<template>

  <section class="blank-page">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <form class="forms-sample" @submit.prevent="handleSubmit">
            <b-form-group id="name" label="Nom du l'utilisateur" label-for="example-input-1">
              <b-form-input
                id="example-input-1"
                name="example-input-1"
                v-model="user.firstname"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>
            </b-form-group>
            <button type="input">MODIFIER</button>
            </form>


            <form autocomplete="off" action="/action_page.php">
              <div class="autocomplete" style="width:300px;">
                <input id="myInput" type="text" v-model="Countries" name="myCountry" placeholder="Country">
              </div>
              <input type="submit">
            </form>

            <input list="europe-countries" placeholder="Start typing...">
<datalist id="europe-countries">
  <option>Russia</option>
  <option>Germany</option>
  <option>United Kingdom</option>
  ...
</datalist>

            <!-- redirection -->

            <router-link to="/dashboard">
            <button> bouton modifier</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
const axios= require('axios').default
export default {
  name: 'essai',
  data () {
      return {
          link: 'essai',
          user:[],
          countries: ["Afghanistan","Albania","Algeria"]
      }
  },
  // methods: {
  //   async handleSubmit() {
  //     axios.put(`https://sites.comolapapaya.com/users/${this.user.ulid}`)
  //     .then(alert('PUT'))
  //     .catch()
  //   }
  // },
  // mounted () {
  //   axios.get('https://sites.comolapapaya.com/users/01F1HKD8D3R905R55JDM2W9Z56')
  //   .then(res=>{
  //     this.user= res.data.result
  //     console.log('res',this.user)
  //     console.log('res-ulid',this.user.ulid)
  //   })
  //   .catch(err=> console.log('err',err))
  // }

mounted () {
  axios.get('https://sites.comolapapaya.com/myinfos')
  .then(res=> {
    this.user= res.data.result
    console.log('user',this.user)
  })
  .catch(err=>console.log(err))
},
methods: {
  async handleSubmit () {
    axios.put(`https://sites.comolapapaya.com/users/${this.user.ulid}`, {
    })
    .then(
      this.$router.push ( { name: 'myinfos'})
    )
    .catch(alert('erreur'))
  }
}
}
</script>

<style>
* { box-sizing: border-box; }
body {
  font: 16px Arial;
}
.autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
  display: inline-block;
}
input {
  border: 1px solid transparent;
  background-color: #f1f1f1;
  padding: 10px;
  font-size: 16px;
}
input[type=text] {
  background-color: #f1f1f1;
  width: 100%;
}
input[type=submit] {
  background-color: DodgerBlue;
  color: #fff;
}
.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}
.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}
.autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}
.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}
</style>